<template>
  <div
      class="container inside-wrapper inside-wrapper-margin"
      id="case-study"
      data-aos="fade-up"
      data-aos-duration="1000"
  >

    <div class="row touchnj-background-img back-white" style="">
      <div class="col-md-5 col-heading">
        <h2 class="heading wow fadeInDown text-blue">Touch New Jersey <a href="http://touchnewjersey.org/"><span
                class="lnr lnr-link"></span></a></h2>
        <br>
        <p class="text-dark wow fadeInUp text-dark text-justify">
          TOUCH New Jersey, Inc. was formed in 2003 operating as a “shopping food pantry” in and around the city of
          Camden, growing gradually and incorporating in 2007. TOUCH New Jersey has been operating in North Camden for
          the past three years and has impacted the local residents to the extent that over 10,000 pounds of food is
          distributed each Wednesday.
          <br><br>
          Member of our Non-Profit Donation program and sponsored by <a href="https://oceanringtech.com/advisorbob/">AdvisorBob</a>.
          <router-link to="/Support" class="text-blue">Learn More</router-link>
        </p>
        <br><br>
        <a href="http://touchnewjersey.org/" class="web-blue" target="_blank"><span class="web-icon-blue"><i
                class="fa fa-globe "></i> </span> <span class="web-text-blue text-bold">View Website</span></a>
        <br><br><br>
      </div>
    </div>
    <section class="case-study">

      <div class="row">
        <div class="col-sm-12">
          <div class="row margin-row-off">
            <div class="col-sm-6">
              <div class="margin-heading-top">

                <h2 class="heding-title">1<span class="sub-title-pad sub-title-text">Challenge</span></h2>
              </div>

              <p class="text-dark wow fadeInUp text-justify">
                TOUCH New Jersey is run by an all volunteer staff and thus struggled to maintain a modern website that
                was responsive in both computer and mobile browsers. Donated funds were used for maintaining the food
                pantry and assisting in partnering with other non-profits but could not be used to find technical talent
                to assist with the site content and events.
                The organization also needed help with graphic design but with no volunteers who were experienced with
                graphic design Touch New Jersey used images found on the internet for social media without any
                customization.
                <br><br>
              </p>

            </div>
            <div class="col-sm-6">
              <div class="app-screens">
                <img width="100%" src="../../assets/img/casestudy-touchnewjersey/casestudy001.jpg">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="app-screens hidden-xs">
                <img width="100%" src="../../assets/img/casestudy-touchnewjersey/casestudy002.jpg">
              </div>
            </div>
            <div class="col-sm-6">
              <div class=" margin-heading-top">
                <h2 class="heding-title">2<span class="sub-title-pad sub-title-text">Obstacles</span></h2>
              </div>
              <ul type="none" class="contactPoints">
                <li>
                  <i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>No room in the budget for technical staff</span>
                </li>
                <li><i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>No volunteers had experience setting up a website and maintaining the content</span>
                </li>
                <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>Unable to find funding for hosting of their site and database.</span>
                </li>
                <li><i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>No experience with email blasts or setting up MailChimp</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="margin-heading-top">
                <h2 class="heding-title">3<span class="sub-title-pad sub-title-text">Solution</span></h2>

              </div>
              <p class="text-dark wow fadeInUp text-justify">
                Ocean Ring Technologies offers enterprise clients quality service and exceptional applications but also
                the opportunity to sponsor non-profits. This partnership allows ORT to donate hours to a non-profit each
                month and through this program we are able to provide Touch New Jersey with 20 hours per month with the
                donations being provided by the startup AdvisorBob.
                With these 20 hours comes the planning and the initial build of their Wordpress site. Staff also
                provides assistance and training on updating content and in cases of mistakes we are more than happy to
                help fix. Ocean Ring Technologies also hosts the site for the non-profit and once the site is completed,
                our creative staff can then begin working with volunteers
                on updated designs, content and social media promotions.
                <br><br>
              </p>
            </div>
            <div class="col-sm-6">
              <div class="app-screens hidden-xs">
                <img width="100%" src="../../assets/img/casestudy-touchnewjersey/casestudy003.jpg">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="app-screens">
                <img width="100%" src="../../assets/img/casestudy-touchnewjersey/casestudy004.jpg">
              </div>
            </div>
            <div class="col-sm-6">
              <div class="margin-heading-top">
                <h2 class="heding-title">4<span class="sub-title-pad sub-title-text">Benefits</span></h2>
              </div>
              <p class="text-dark wow fadeInUp text-justify">
                Our social good program allows non-profits to find quality technical support and development that fits
                within their budget as well as provide enterprise clients another source of PR as non-profits are always
                willing to promote their sponsor and how much their good will is appreciated.
                It has always been a goal of Ocean Ring Technologies to provide technical expertise to non-profits and
                assist them in reaching their goals.
                <br><br>
              </p>
            </div>


          </div>
          <div class="col-sm-8  col-technologies text">
            <h2 class="text-dark border-dotted-blue">Whichever services you require, we have the skills to match.</h2>
            <router-link to="/contact" class="btn-red hvr-sweep-to-right hvr-sweep-to-right-red">Contact</router-link>
          </div>
        </div>
      </div>
    </section>

    <div class="row contact-fourth back-red">
      <div class="col-sm-5 bottom-links text-left">
        <router-link to="/">
          <h2 class="heading text-light">
            <i class="fa fa-long-arrow-left"> </i> Home
          </h2>
        </router-link>
      </div>
      <div class="col-sm-2">
        <p></p>
      </div>
      <div class="col-sm-5 bottom-links text-right">
        <router-link to="/news">
          <h2 class="heading text-light">
            News <i class="fa fa-long-arrow-right"> </i>
          </h2>
        </router-link>
      </div>
    </div>


  </div>
</template>

<script>
  export default {
    name: "work",
    async mounted() {
      window.scrollTo(0, 0);
    },
  };
</script>

<style scoped>
</style>
